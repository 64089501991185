<style lang="scss" scoped>

.login{
    width: 100%;
    height: 100%;
    background-color: $light;
    background-image: url('../../assets/login/background.png');
    background-size: cover;
    position: relative;
    .login-container{
        position: absolute;
        left:100px;
        top:50%;
        transform: translateY(-50%);
        .title{
            font-size: 36px;
            font-weight: bold;  
        }
        .sub-title{
            font-size: 36px;
            font-weight: bold; 
            margin-bottom: 12px;
        }
        .input-item{
            width: 300px;
            margin-bottom: 12px;
        }
    }
}
</style>

<template>
    <div class="login">
        <div class="login-container">
            <div class="title">登录到</div>
            <div class="sub-title">财务管理平台</div>
            <div class="input-item">
           
            </div>
            <div class="input-item">
                <el-input placeholder="请输入公司名称" v-model="company" disabled>
                </el-input>
            </div>
            <div class="input-item">
                <el-input  prefix-icon="el-icon-user" @keyup.enter.native="login" placeholder="请输入用户名" v-model="username">
                </el-input>
            </div>
            <div class="input-item">
                <el-input  prefix-icon="el-icon-lock" @keyup.enter.native="login" type="password" placeholder="请输入密码" show-password v-model="password">
                </el-input>
            </div>
            <div class="input-item">
                <el-button style="width:100%" type="primary" @click="login">登录</el-button>
            </div>
        </div>
    </div>
</template>


<script>
export default{
    data(){
        return {
            value:1000,
            company:"无锡零拓数字科技有限公司",
            // company:"无锡零拓数字能源科技有限公司",
            username:"",
            password:""
        }
    },
    methods:{
        async login(){
            if(this.username==''){
                this.$notify.error("请输入用户名！")
                return
            }

            if(this.password==''){
                this.$notify.error("请输入密码！")
                return
            }

            this.$store.dispatch("login",{username:this.username,password:this.password})
        }
    }
}
</script>